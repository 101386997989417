import "./Footer.css";

function Footer() {
   return (
      <footer>
         <p>Made with ❤️ from TX | All Rights Reserved | Pedia Digital&trade; &copy;2022</p>
         <a href="https://www.activesearchresults.com/"><img src="https://www.activesearchresults.com/images/asrbutton.png" alt="Active Search Results" width="88" height="31" border="0" /></a>
      </footer>
   );
}

export default Footer;
