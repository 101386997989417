/*import logo from './logo.svg';*/
import './App.css';
// import fontawesome library
import './fontawesome';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, falCode, fal } from '@fortawesome/free-solid-svg-icons';
/*import Reveal from "react-awesome-reveal";*/
import { 
  Bounce, 
  Slide, 
  Fade, 
  Flip,
  Roll,
  Zoom,
} from "react-awesome-reveal";


function App() {
  return (
    <div className="App">
      
      <div class="container">
        <div class="row navHeight">
          <div className="col-sm-12 text-center title">
            <Bounce>
              <a href="/">Pedia Digital <FontAwesomeIcon icon={faCode} /></a>
            </Bounce>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 col-sm-12 services">
            <div class="row justify-content-center grid-container">
              <div class="col-md-6 col-xs-12 col-sm-12">
                <Flip>
                  <h2 class="sub-title"><FontAwesomeIcon icon={['fa', 'code']} /> Services</h2>
                </Flip>
                <ul>
                  <li>Web Development</li>
                  <li>Web Hosting</li>
                  <li>Logos & Social Media</li>
                </ul>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-12">
                <Roll>
                  <h2 class="sub-title"><FontAwesomeIcon icon={faCode} /> Skills</h2>
                </Roll>
                <ul>
                  <li>HTML, Markdown, CSS, JavaScript,</li>
                  <li>NodeJS, Python, Bootstrap,</li>
                  <li>Git, SEO, SQL DB</li>
                </ul>
              </div>
            </div>
            <div class="row justify-content-center grid-container">
              <div class="col-md-6 col-xs-12 col-sm-12">
                <Flip>
                  <h2 class="sub-title"><FontAwesomeIcon icon={faCode} /> About</h2>
                </Flip>
                <ul class="about">
                  <li>Front-End/Back-End Dev</li>
                  <li>Internet Entrepreneur</li>
                  <li>Freelancer</li>
                </ul>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-12">
                <Roll>
                  <h2 class="sub-title"><FontAwesomeIcon icon={faCode} /> Contact</h2>
                </Roll>
                <ul class="contacts">
                  <li>Email : <a class="email" href="mailto:contact@pediadigital.com"> contact@pediawebservices.com</a></li>
                  <li>Mobile : <a class="phone" href="callto:5123875355"> 512-387-5355</a></li>
                  <li>Phone : <a class="phone" href="callto:18779260052"> 1-877-926-0052</a></li>
                  {/*<li>On<a href="https://twitter.com/Jay52_TX" target="_blank" rel="noreferrer"> Twitter</a></li>*/}
                </ul>
              </div>
            </div>
            <div class="row justify-content-center grid-container">
              <div class="col-md-6 col-xs-12 col-sm-12">
                <Flip>
                  <h2 class="sub-title"><FontAwesomeIcon icon={faCode} /> Experience</h2>
                </Flip>
                <ul>
                  <li>Front/Back-end Diploma</li>
                  <li>20+ years in IT</li>
                  <li>60+ Projects</li>
                </ul>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-12">
                <Roll>
                  <h2 class="sub-title"><FontAwesomeIcon icon={faCode} /> Links</h2>
                </Roll>
                <ul class="links">
                  <li><a href="https://www.pediawebservices.com/products/plesk" target="_blank" rel="noreferrer">Hosting</a></li>
                  <li><a href="https://www.pediawebservices.com/" target="_blank" rel="noreferrer">Domain Registration</a></li>
                  <li><a href="https://www.thesocialpal.com/" target="_blank" rel="noreferrer">Social Media Mgmt</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-container container">
        <Slide>
          <form 
          id="contact" 
          name="contact-form"
          method="post" 
          action="thank-you" 
          data-netlify="true" 
          data-netlify-recaptcha="true" 
          >
            <input type="hidden" name="form-name" value="contact-form" />
            <h2 className="sub-title">Get In-touch!</h2>
            <h5>Please fill out the form below with your info and inquiries.</h5>
            <div class="form-row">
              <div class="col">
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="text" class="form-control" placeholder="First name" name="first-name"  required />
              </div>
              <div class="col">
                <input type="text" class="form-control" placeholder="Last name" name="last-name" required />
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <input type="email" class="form-control" placeholder="Email" name="email" required />
              </div>
              <div class="col">
                <input type="tel" class="form-control" placeholder="Phone#" name="phone-number" required />
              </div>
            </div>
            <div class="col">
              <textarea type="text" class="form-control" placeholder="Your message here..." name="form-message" required></textarea>
            </div>
            <button type="submit" class="btn btn-primary" name="submit">Send! </button>
            <br /><br />
            <div data-netlify-recaptcha="true"></div>
          </form>
        </Slide>
       </div>

    </div>
  );
}

export default App;
